import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { FiLayers, FiDownload } from 'react-icons/fi';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function SrtToVttConverter() {
  const [files, setFiles] = useState([]);
  const [convertedFiles, setConvertedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFolderChange = (event) => {
    const selectedFiles = Array.from(event.target.files).filter(file => file.name.endsWith('.srt'));
    setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
  };

  const convertSrtToVtt = async () => {
    if (files.length === 0) return;

    const converted = await Promise.all(files.map(async (file) => {
      const content = await readFileContent(file);
      const vttContent = 'WEBVTT\n\n' + content.replace(/(\d{2}:\d{2}:\d{2}),(\d{3})/g, '$1.$2');
      return { name: file.name.replace('.srt', '.vtt'), content: vttContent };
    }));

    setConvertedFiles(converted);
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsText(file);
    });
  };

  const downloadZip = async () => {
    const zip = new JSZip();

    convertedFiles.forEach(file => {
      zip.file(file.name, file.content);
    });

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "converted_vtt_files.zip");
  };

  const handleSelectFolders = () => {
    fileInputRef.current.click();
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-white dark:bg-gray-900 p-8 rounded-3xl shadow-custom max-w-md w-full"
    >
      <h1 className="text-2xl font-bold mb-6 text-left text-gray-900 dark:text-white">SRT to VTT Converter</h1>
      <div className="mb-6">
        <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 text-left" htmlFor="folder">
          Select folders with SRT files
        </label>
        <div className="flex items-center justify-start w-full">
          <button
            onClick={handleSelectFolders}
            className="w-full flex flex-col items-start px-4 py-6 bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 rounded-xl shadow-md tracking-wide border border-gray-300 dark:border-gray-700 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-200 ease-in-out"
          >
            <FiLayers className="w-6 h-6 mb-2" />
            <span className="text-sm">
              {files.length > 0 ? `${files.length} SRT files selected` : 'Select folders'}
            </span>
          </button>
          <input 
            ref={fileInputRef}
            type='file' 
            className="hidden" 
            onChange={handleFolderChange} 
            webkitdirectory="true"
            directory="true"
            multiple
          />
        </div>
      </div>
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="w-full bg-[#ff5533] hover:bg-[#ff6644] text-white font-bold py-3 px-4 rounded-xl transition duration-200 ease-in-out"
        onClick={convertSrtToVtt}
      >
        Convert to VTT
      </motion.button>
      {convertedFiles.length > 0 && (
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="w-full mt-4 bg-black dark:bg-white hover:bg-gray-800 dark:hover:bg-gray-200 text-white dark:text-black font-bold py-3 px-4 rounded-xl flex items-center justify-center transition duration-200 ease-in-out"
          onClick={downloadZip}
        >
          <FiDownload className="mr-2" />
          Download ZIP ({convertedFiles.length} files)
        </motion.button>
      )}
      <div className="mt-4 text-xs text-gray-500 dark:text-gray-400 text-center">
        Made by Prateek Keshari
      </div>
    </motion.div>
  );
}

export default SrtToVttConverter;